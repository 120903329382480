import React from "react"
// import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"
import Comments from "../components/Comments"
import SidebarPostInfo from "../components/SidebarPostInfo"
import { CloudinaryMediaCarousel } from "../components/CloudinaryMedia"
// Material UI Core
import withStyles from "@material-ui/core/styles/withStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import Divider from "@material-ui/core/Divider"
// Material UI Kit Components
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"
import imagesStyles from "../assets/jss/material-kit-pro-react/imagesStyles"

const baseUrl = `${process.env.GATSBY_API_URL}`

const style = {
  ...withStyles,
  ...imagesStyles,
  root: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
    height: "auto",
  },
  imageFullWidth: {
    width: "100%",
    height: "auto",
    borderRadius: "4px",
  },
  carousel: {
    borderRadius: "6px",
    overflow: "hidden",
  },
  iframeWrapper: {
    position: "relative",
    width: "100% !important",
    height: "0 !important",
    paddingBottom: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100% !important",
    height: "100% !important",
  },
}

function ArticleTemplate({ ...props }) {
  const isMobile = useMediaQuery("(max-width:767px)")
  const {
    pageContext: {
      pageData: {
        avatar,
        created,
        bodyRaw,
        title,
        drupalId,
        type,
        fieldImage,
        fieldLike,
        relationships,
        drupalInternalNid,
      },
    },
  } = props

  let article_body_elements = new ReactHtmlParser(bodyRaw, {
    transform: function transform(node) {
      if (node.type === "tag" && node.name === "img") {
        // const images = data.allFile.edges;
        const remoteImg = node.attribs["src"]
        return (
          <img
            key={remoteImg}
            style={style.imageFullWidth}
            src={remoteImg.startsWith("http") ? remoteImg : baseUrl + remoteImg}
            alt={title}
          />
        )
      } else if (node.type === "tag" && node.name === "iframe") {
        let url = node.attribs.src
        if (url.search("http") === -1) {
          if (url.search("//") === -1) {
            url = "//" + url
          }
          url = "https:" + url
        }
        let id = url.split("/").pop()
        return (
          <div key={id} style={style.iframeWrapper}>
            <iframe
              src={url}
              width={"100%"}
              height={"100%"}
              id={id}
              allowFullScreen
              style={style.iframe}
            />
          </div>
        )
      }
      return undefined
    },
  })

  const sidebar = () => (
    <SidebarPostInfo
      postId={drupalInternalNid}
      uuid={drupalId}
      type={type}
      created={created}
      authorName={relationships.uid.name}
      authorImage={avatar}
      fieldLike={fieldLike}
    />
  )

  return (
    <Layout {...props}>
      <div style={style.root}>
        <GridContainer
          id={"sidebar"}
          spacing={3}
          direction="row"
          justify="center"
          alignContent="center"
        >
          {!isMobile && (
            <GridItem xs={12} md={2}>
              {sidebar()}
            </GridItem>
          )}
          <GridItem id={"card-wrapper"} xs={12} md={10}>
            <Card
              id={"card"}
              style={style.carousel}
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <div id={"carousel-wrapper"}>
                <CloudinaryMediaCarousel
                  imageArray={fieldImage}
                  transformations={
                    isMobile
                      ? "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_limit"
                      : "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_pad"
                  }
                  progressive={
                    isMobile ? "w_1024,h_576,c_limit" : "w_1024,h_576,c_pad"
                  }
                />
                {isMobile && sidebar()}
              </div>
              <CardBody>
                <h2 style={{ textAlign: "center" }}>{title}</h2>
                <Divider light style={{ marginBottom: "20px" }} />
                <div style={style.fullWidth}>{article_body_elements}</div>
              </CardBody>
            </Card>
            <div style={{ padding: "10px" }}>
              <Comments id={drupalId} type={type} />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}

export default ArticleTemplate
